<template>
	<!-- 预出库汇总 -->
	<div class="preDeliverySummary">
		<!-- 打印模板 -->
		<div id="export" style="position: fixed;top: -9999999px;">
			<div class="export-template">
				<div id="template-title">
					<div style="line-height: 50px;font-size: 22px;" align=center>
						<strong>
							<!-- <font>{{ routeName ? routeName : '全部' }}-配送汇总</font> -->
							<font>配送汇总</font>
						</strong>
					</div>
				</div>
				<div class="title-box">
					<div class="box-l">
						<div class="l-item">
							<span>打印时间：{{ currentDateTime }}</span>
						</div>
						<div class="l-item">
							<span>配送路线：{{ routeName ? routeName : '全部' }}</span>
						</div>
						<div class="l-item">
							<span>配送单状态：{{ statusName ? statusName : '全部' }}</span>
						</div>
					</div>
				</div>
				<div id="template-table">
					<table border=1 cellSpacing=0 cellPadding=1 width="100%" style="border-collapse:collapse"
						bordercolor="#333333">
						<thead>
							<tr>
								<td width="10%" style="padding:6px 0">
									<div align=center><b>序号</b></div>
								</td>
								<td width="35%" style="padding:6px 0">
									<div align=center><b>商品名称</b></div>
								</td>
								<td width="20%" style="padding:6px 0">
									<div align=center><b>商品介绍</b></div>
								</td>
								<td width="20%" style="padding:6px 0">
									<div align=center><b>自定义属性</b></div>
								</td>
								<td width="15%" style="padding:6px 0">
									<div align=center><b>数量</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in tableData" :key="index">
								<td align=center style="padding:6px 0">{{ index + 1 }}</td>
								<td align=center style="padding:6px 0">{{ item.goods_name }}</td>
								<td align=center style="padding:6px 0">{{ item.goods_intro }}</td>
								<td align=center style="padding:6px 0">{{ item.goods_attr }}</td>
								<td align=center style="padding:6px 0">{{ item.count }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<!-- 待配商品 -->
		<div id="print_summary_detail" style="position: fixed;top: -9999999px;">
			<div class="export-template">
				<div id="template-summary-title">
					<div style="line-height: 50px;font-size: 22px;" align=center>
						<strong>

							<font>{{ goodsItem.goods_name }}</font>
						</strong>
					</div>
				</div>
				<div class="title-box">
					<div class="box-l">
						<div class="l-item">
							<span>配送日期：{{ searchForm.date }}</span>
						</div>
						<div class="l-item">
							<span>打印时间：{{ currentDateTime }}</span>
						</div>
					</div>
				</div>
				<div id="template-summary-table">
					<table border=1 cellSpacing=0 cellPadding=1 width="100%" style="border-collapse:collapse"
						bordercolor="#333333">
						<thead>
							<tr>
								<td width="10%" style="padding:6px 0">
									<div align=center><b>序号</b></div>
								</td>
								<td width="15%" style="padding:6px 0">
									<div align=center><b>联系人</b></div>
								</td>
								<td width="15%" style="padding:6px 0">
									<div align=center><b>联系电话</b></div>
								</td>
								<td width="30%" style="padding:6px 0">
									<div align=center><b>配送地址</b></div>
								</td>
								<td width="15%" style="padding:6px 0">
									<div align=center><b>订单数量</b></div>
								</td>
								<td width="15%" style="padding:6px 0">
									<div align=center><b>配送数量</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in detailTableData" :key="index">
								<td align=center style="padding:6px 0">{{ index + 1 }}</td>
								<td align=center style="padding:6px 0">{{ item.contacts }}</td>
								<td align=center style="padding:6px 0">{{ item.phone }}</td>
								<td align=center style="padding:6px 0">
									{{ item.delivery_type == 1 ? item.address : '自提' }}
								</td>
								<td align=center style="padding:6px 0">{{ item.count }}</td>
								<td align=center style="padding:6px 0">{{ item.ck_count }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item">
					<div class="item-label">配送日期:</div>
					<div class="item-input">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送路线:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_line_id" placeholder="请选择" @input="changeDelivery">
							<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择状态" @input="changeStatus">
							<el-option v-for="item in statusType" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">商品名称:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入包含的商品名称的关键字" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1],getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, dateVal = [getCurrentDate(),getCurrentDate()],searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="success" @click="handlePrint">打印</el-button>
				<el-button @click="handleExport">导出</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<el-table :data="tableData" style="width: 100%" border :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }" v-loading="loading">
				<el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
				<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
				<el-table-column label="商品主图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column prop="goods_intro" label="商品卖点" align="center"></el-table-column>
				<el-table-column prop="goods_attr" label="自定义属性" align="center"></el-table-column>
				<el-table-column prop="price" label="订单价" sortable align="center"></el-table-column>
				<el-table-column prop="count" label="订单数量" sortable align="center"></el-table-column>
				<el-table-column prop="amount" label="订单金额" sortable align="center"></el-table-column>
				<el-table-column prop="ck_count" label="配送数量" sortable align="center"></el-table-column>
				<el-table-column prop="ck_amount" label="配送金额" sortable align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="showAddressDetail(scope.row)">详细</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :title="goodsItem.goods_name+'配送详细'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="80%" @close="closeDetailDialog">
			<div class="summary-dialog">
				<el-table :data="detailTableData" style="width: 100%" border :header-cell-style="{
					background: '#f5f7fa',
					fontWeight: 'bold',
					color: '#303133'
				}" v-loading="loading">
					<el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
					<el-table-column prop="contacts" label="联系人" align="center"></el-table-column>
					<el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
					<el-table-column prop="address" label="配送地址" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.delivery_type == 1">{{ scope.row.address }}</span>
							<span v-else>自提</span>
						</template>
					</el-table-column>
					<el-table-column prop="price" label="订单价" align="center"></el-table-column>
					<el-table-column prop="count" label="订单数量" sortable align="center"></el-table-column>
					<!-- <el-table-column prop="amount" label="订单金额" align="center"></el-table-column> -->
					<el-table-column prop="ck_count" label="配送数量" sortable align="center"></el-table-column>
					<!-- <el-table-column prop="ck_amount" label="配送金额" align="center"></el-table-column> -->
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">

				<el-button type="success" @click="handlePrintDetail" v-loading.fullscreen.lock="fullscreenLoading">打
					印</el-button>
				<el-button @click="handleExportDetail" v-loading.fullscreen.lock="fullscreenLoading">导 出</el-button>
				<el-button type="info" @click="closeDetailDialog">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				currentDateTime: this.getCurrentDateTime(),
				searchForm: {
					begin: '',
					end: '',
					status: '',
					delivery_line_id: ''
				},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 状态
				statusType: [{
						id: 0,
						name: '待确认'
					},
					{
						id: 1,
						name: '已确认'
					},
					{
						id: 2,
						name: '已取消'
					},
				],
				statusName: '',
				routeList: [], //配送路线
				routeName: '',
				//地址明细
				dialogVisible: false,
				detailTableData: [],
				goodsItem: {}
			}
		},
		mounted() {
			//this.searchForm.date = this.getCurrentDate()
			// 列表
			this.getRouteList()
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			this.getList()
		},
		methods: {
			showAddressDetail(item) {
				this.goodsItem = item
				this.dialogVisible = true
				this.$http.post(`erp/v1/preck/summary_detail`, {
					goods_id: item.goods_id,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.detailTableData = data
					} else {
						this.$message.error(msg);
					}
				});

			},
			handlePrintDetail() {
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById(
							"print_summary_detail")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
			handleExportDetail() {
				this.loading = true
				this.$http.post(`erp/v1/preck/summary_detail`, {
					export: 1,
					goods_id: this.goodsItem.goods_id,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url)
					} else {
						this.$message.error(msg);
					}
				});
			},
			closeDetailDialog() {
				this.dialogVisible = false
				this.detailTableData = []
				this.goodsItem = {}
			},
			handleExport() {
				this.loading = true
				this.$http.post(`erp/v1/preck/summary`, {
					export: 1,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.loading = false
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			getList() {
				this.$http.post(`erp/v1/preck/summary`, {
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
						this.routeList.unshift({
							delivery_line_id: '',
							title: '全部'
						})
					} else {
						this.$message.error(msg);
					}
				});
			},
			changeDelivery(e) {
				this.routeList.forEach(item => {
					if (item.id == e) {
						this.routeName = item.title
					}
				});
			},
			changeStatus(e) {
				this.statusType.forEach(item => {
					if (item.id == e) {
						this.statusName = item.name
					}
				});
			},
			// 打印
			handlePrint(item) {
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById("export")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
		},
	}
</script>

<style lang="scss" scoped></style>